<template>
  <nuxt-link class="card-providers" :to="pathTo">
    <div class="img">
      <atomic-image
        :src="`${gamehubCdn}/providers/images/${providerData.identity}/cl/dark`"
        :defaultImage="`${gamehubCdn}/providers/images/provider-placeholder.png`"
      />
    </div>

    <div class="name">
      <span>{{ providerData.name }}</span>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
  import { urlFormat } from '@skeleton/utils/routerUtils';

  import type { IGameProvider } from '@skeleton/core/types';

  const props = defineProps<{
    providerData: IGameProvider;
  }>();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const { localizePath } = useProjectMethods();

  const route = useRoute();

  const pathTo = computed(() => {
    const basePath = `/games/providers/${urlFormat(props.providerData.identity)}`;

    return {
      path: localizePath(basePath),
      query: route.query,
    };
  });
</script>

<style src="~/assets/styles/components/card/providers.scss" lang="scss" />
